<template>
  <div class="page-create-box">
    <div class="create-pane">
      <div class="create-pane__left">
        <vh-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="80px"
          class="std-form"
        >
          <!-- 标题 -->
          <vh-form-item label="标题" prop="name">
            <vh-input
              v-model="ruleForm.name"
              placeholder="请输卡片入标题"
              maxlength="20"
              show-word-limit
            ></vh-input>
          </vh-form-item>

          <!-- 展示样式 -->
          <vh-form-item label="展示样式" prop="kind" class="auto-height">
            <vh-radio-group v-model="ruleForm.kind">
              <vh-radio label="dialog" class="lh-20">弹窗样式</vh-radio>
              <vh-radio label="bubble" class="lh-20">气泡提醒</vh-radio>
            </vh-radio-group>
          </vh-form-item>

          <!-- 图片比例 -->
          <vh-form-item label="图片比例">
            <div class="content-body content-body--shape">
              <div class="content-body__box">
                <div
                  class="content-body__card"
                  :class="[{ 'is-active': ruleForm.shape == 'vertical' }]"
                  @click="handleChangeCard('vertical')"
                >
                  <div class="card-content card-content--vertical"></div>
                </div>
                <p class="content-body__desc">竖版</p>
              </div>

              <div class="content-body__box">
                <div
                  class="content-body__card"
                  :class="[{ 'is-active': ruleForm.shape == 'horizontal' }]"
                  @click="handleChangeCard('horizontal')"
                >
                  <div class="card-content card-content--horizontal"></div>
                </div>
                <p class="content-body__desc">横版</p>
              </div>

              <div class="content-body__box">
                <div
                  class="content-body__card"
                  :class="[{ 'is-active': ruleForm.shape == 'square' }]"
                  @click="handleChangeCard('square')"
                >
                  <div class="card-content card-content--square"></div>
                </div>
                <p class="content-body__desc">方形</p>
              </div>
            </div>
          </vh-form-item>

          <!-- TODO: 需要封装一个‘上传并剪裁’的业务组件 -->
          <vh-form-item label="图片" required>
            <vh-upload class="content-upload" action="https://jsonplaceholder.typicode.com/posts/">
              <i class="iconfont-v3 saasicon_shangchuan"></i>
              <div class="text-tips">
                <p>建议尺寸：720*960px，小于4M</p>
                <p>支持jpg、gif、png、bmp</p>
              </div>
            </vh-upload>
          </vh-form-item>

          <!-- 描述 -->
          <vh-form-item label="描述" prop="description">
            <vh-input
              v-model="ruleForm.description"
              placeholder="请输描述"
              maxlength="40"
              show-word-limit
            ></vh-input>
          </vh-form-item>

          <!-- 跳转链接 -->
          <vh-form-item label="跳转链接" prop="goto" class="auto-height mb-24">
            <div class="content-title" style="display: flex">
              <vh-switch
                v-model="ruleForm.goto"
                active-color="#FB3A32"
                inactive-color="#CECECE"
              ></vh-switch>
              <span class="switch-label">
                {{
                  ruleForm.goto
                    ? '已开启，可设置跳转链接进行引流'
                    : '开启后，可设置跳转链接进行引流'
                }}
              </span>
            </div>
          </vh-form-item>

          <!-- 按钮文案 -->
          <vh-form-item label="按钮文案" prop="buttonText" v-if="ruleForm.goto" class="mb-24">
            <vh-input
              v-model="ruleForm.buttonText"
              placeholder="请输文案"
              maxlength="15"
              show-word-limit
            ></vh-input>
          </vh-form-item>

          <!-- 访问链接 -->
          <vh-form-item label="访问链接" prop="goLink" required v-if="ruleForm.goto">
            <vh-input
              v-model="ruleForm.goLink"
              placeholder="请输入链接"
              maxlength="15"
              show-word-limit
            ></vh-input>
          </vh-form-item>

          <!-- 倒计时 -->
          <vh-form-item label="倒计时" prop="countdown" class="auto-height">
            <div class="content-title" style="display: flex">
              <vh-switch
                v-model="ruleForm.countdown"
                active-color="#FB3A32"
                inactive-color="#CECECE"
              ></vh-switch>
              <span class="switch-label">
                {{
                  ruleForm.countdown
                    ? '已开启，倒计时结束后卡片自动关闭'
                    : '开启后，倒计时结束后卡片自动关闭'
                }}
              </span>
            </div>
            <div class="content-body" style="margin-top: 15px" v-if="ruleForm.countdown">
              <vh-radio-group v-model="ruleForm.countdownNum">
                <vh-radio :label="5">5秒</vh-radio>
                <vh-radio :label="10">10秒</vh-radio>
                <vh-radio :label="15">15秒</vh-radio>
                <vh-radio label="custom">
                  <vh-input
                    v-model="ruleForm.countdownNumCustom"
                    type="number"
                    :max="300"
                    autocomplete="off"
                    placeholder="3-300"
                    @focus="ruleForm.countdownNum = 'custom'"
                    class="radio-input"
                  >
                    <template slot="suffix">秒</template>
                  </vh-input>
                </vh-radio>
              </vh-radio-group>
            </div>
          </vh-form-item>

          <vh-form-item>
            <vh-button round type="primary" @click="submitForm('ruleForm')">保存</vh-button>
            <vh-button round type="info" plain @click="resetForm('ruleForm')">取消</vh-button>
          </vh-form-item>
        </vh-form>
      </div>
      <div class="create-pane__right">
        <SimulatorPreview>
          <!-- TODO：跟随左侧内容变化 -->
          <div class="mobile-preview" slot="mobile">这里跟需要随左侧操作变化内容</div>
          <div class="mobile-preview" slot="pc">这里跟需要随左侧操作变化内容</div>
        </SimulatorPreview>
      </div>
    </div>
  </div>
</template>

<script>
  import SimulatorPreview from '@/components/SimulatorPreview';
  export default {
    name: 'TabContent3',
    components: {
      SimulatorPreview
    },
    data() {
      return {
        ruleForm: {
          name: '', // 直播标题
          kind: 'dialog', // 抽奖提示 dialog:弹窗； bubble 气泡；
          shape: 'vertical', // 图片比例 vertical:竖版； horizontal 横版； square 方形；
          description: '', //描述
          goto: true, // 跳转链接
          buttonText: '', // 按钮文案
          goLink: '', // 访问链接
          countdown: true,
          countdownNum: 10,
          countdownNumCustom: undefined
        },
        rules: {
          name: [
            { required: true, message: '请输入卡片标题', trigger: 'blur' },
            { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
          ],
          goLink: [
            { required: true, message: '请输入链接', trigger: 'blur' },
            { min: 1, max: 15, message: '长度在 1 到 15 个字符', trigger: 'blur' }
          ]
        }
      };
    },
    methods: {
      handleChangeCard(val) {
        this.ruleForm.shape = val;
      },
      submitForm(formName) {
        this.$refs[formName].validate(valid => {
          if (valid) {
            alert('submit!');
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      }
    }
  };
</script>
<style lang="less" scoped>
  // 图片比例卡片
  .content-body--shape {
    .content-body__box {
      text-align: center;
      margin-left: 16px;

      &:first-child {
        margin-left: 0;
      }
    }

    .content-body__card {
      width: 64px;
      height: 104px;

      .card-content {
        position: relative;
        width: 100%;
        height: 100%;
        &:before {
          content: '';
          position: absolute;
          border-radius: 4px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background: rgba(0, 0, 0, 0.06);
        }
        &:after {
          content: '';
          position: absolute;
          width: 20px;
          height: 20px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background-repeat: no-repeat;
          background-size: contain;
          background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHVSURBVHgB7Zi9T8MwEMUfLQNDhw4dOiBkoQ4dGBgYGPj/xcDA0IGBoUIdOkaoQh0Q4l56IU0/8KVJ7LTqT3pqHEX189nnL6DlXKw990VO1BNdIg6JaK5K6eqvE41FV6IO4sH6B/pMs2mkhlgZJFPRTPSN8GReRuqHBpOuvqDzd9GH6AdxYL2f+txXT/OOFsgc7WCmv8yFwniL0a27yHykiRozIUz4phOOA2Y3h8FCNBEtERBfBO+Qj1GOiTEC4zPY2yj3ERifwWSjvEBgfAbfkJui2QkC40sSJsQLItL6aeZssCpHv5JU+V9O8lyJXlFh9Wkqgg75lolGDw5EEwad6HqtzNVohAOp2yCNuR3vh3veeylr0IketMJNfJFyKEbWRBmDDvmpj7uadZPZWPMxwvYG5F+sBh22u4gmGREmwD1WJi2U+dZk0GH/+GFEnspUiLxBpsy2GBygftggU1dbDDZx2kuwvdfciSXMM+RHweCcxFr8iHJJYIFr87Plw5OIoKmlTXEUG9bCXUgLKPigwexYWXohb4hsjU/nSd4PLvUlN5iM5hfi3BEycjeiWy1PRYvsjtrhwP1aQ0xVf3fUDCcjyVbUPedZYe/xhpW3GW25TPXzC/gIPcGtC4Y1AAAAAElFTkSuQmCC);
        }
        &--vertical:before {
          width: 43px;
          height: 57px;
        }
        &--horizontal:before {
          width: 52px;
          height: 38px;
        }
        &--square:before {
          width: 48px;
          height: 48px;
        }
      }
    }

    .content-body__desc {
      font-size: 14px;
      text-align: center;
      line-height: normal;
      color: #666;
      margin-top: 8px;
    }
  }
  .mobile-preview {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
  }
</style>
